/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import withPostService from '../../../../hoc/with-post-service';
import withGetService from '../../../../hoc/with-get-service';
import { compose } from '../../../../../utils';
import CreateNewOrderView from './create-new-order-view';
import ErrorIndicator from '../../../error-page/error-indicator';
import getAvailableNewPairsAction from '../../../../../actions/getAvailableNewPairs.actions';
import fetchCreateScheduledOrderAction from '../../../../../actions/postCreateScheduledOrder.actions';
import { personalAreaPath, ordersPath } from '../../../../../constants';

export class CreateNewOrderContainer extends Component {
    state = {
        pairName: '',
        userId: '',
        buySellCoef: '',
        buyValueMin: '',
        buyValueMax: '',
        ordersQuantityMin: '',
        ordersQuantityMax: '',
        frequencyMin: '',
        frequencyMax: '',
        volumeLimit: '',
        minOrderVolume: '',
        maxOrderVolume: '',
        fakeVolumeControl: false,
        errors: {},
    };

    componentDidMount() {
        const { getAvailableNewPairs } = this.props;
        getAvailableNewPairs();
    }

    componentDidUpdate(prevProps) {
        const { successScheduledOrder, history } = this.props;

        if (successScheduledOrder && successScheduledOrder !== prevProps.successScheduledOrder) {
            this.setState({
                pairName: '',
                userId: '',
                buySellCoef: '',
                buyValueMin: '',
                buyValueMax: '',
                ordersQuantityMin: '',
                ordersQuantityMax: '',
                frequencyMin: '',
                frequencyMax: '',
                volumeLimit: '',
                minOrderVolume: '',
                maxOrderVolume: '',
                errors: {},
            });
            history.push(`${personalAreaPath}${ordersPath}`);
        }
    }

    // save input changes to state
    inputOnchange = event => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    };

    // save select changes to state
    selectOnChange = (value, name) => {
        this.setState({
            [name.props.name]: value,
        });
    };

    fakeVolumeControlToggle = event => {
        const { checked } = event.target;
        this.setState({
            fakeVolumeControl: checked,
        });
    };

    confirm = () => {
        const { t, createScheduledOrder } = this.props;
        const {
            pairName,
            userId,
            buySellCoef,
            buyValueMin,
            buyValueMax,
            ordersQuantityMin,
            ordersQuantityMax,
            frequencyMin,
            frequencyMax,
            volumeLimit,
            minOrderVolume,
            maxOrderVolume,
            fakeVolumeControl,
        } = this.state;
        const errors = {};

        if (pairName.length < 1) {
            errors.pairNameError = t('general.selectFromTheList');
        }

        // if (userId.length < 1) {
        //     errors.userIdError = t('error.input', { count: 1 });
        // }

        if (buySellCoef.length < 1) {
            errors.buySellCoefError = t('error.input', { count: 1 });
        }

        if (buyValueMin.length < 1) {
            errors.buyValueMinError = t('error.input', { count: 1 });
        }

        if (buyValueMax.length < 1) {
            errors.buyValueMaxError = t('error.input', { count: 1 });
        }

        if (ordersQuantityMin.length < 1) {
            errors.ordersQuantityMinError = t('error.input', { count: 1 });
        }

        if (ordersQuantityMax.length < 1) {
            errors.ordersQuantityMaxError = t('error.input', { count: 1 });
        }

        if (frequencyMin.length < 1) {
            errors.frequencyMinError = t('error.input', { count: 1 });
        }

        if (frequencyMax.length < 1) {
            errors.frequencyMaxError = t('error.input', { count: 1 });
        }

        if (volumeLimit.length < 1) {
            errors.volumeLimitError = t('error.input', { count: 1 });
        }

        if (minOrderVolume.length < 1) {
            errors.minOrderVolumeError = t('error.input', { count: 1 });
        }

        if (maxOrderVolume.length < 1) {
            errors.maxOrderVolumeError = t('error.input', { count: 1 });
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
            message.error(t('general.errorFields'), 2);
        } else {
            const stringValue = pairName;
            const splitString = stringValue.split('-');
            const data = {
                userId,
                buySellCoef,
                buyValueMin,
                buyValueMax,
                ordersQuantityMin,
                ordersQuantityMax,
                frequencyMin,
                frequencyMax,
                volumeLimit,
                minOrderVolume,
                maxOrderVolume,
                fakeVolumeControl,
                orders: [],
                pair: {
                    left: splitString[0],
                    right: splitString[1],
                },
            };
            createScheduledOrder(data, t);
        }
    };

    onError = () => {
        this.setState({
            error: true,
        });
    };

    render() {
        const {
            error,
            pairName,
            userId,
            buySellCoef,
            buyValueMin,
            buyValueMax,
            ordersQuantityMin,
            ordersQuantityMax,
            frequencyMin,
            frequencyMax,
            volumeLimit,
            minOrderVolume,
            maxOrderVolume,
            fakeVolumeControl,
            errors: {
                pairNameError,
                userIdError,
                buySellCoefError,
                buyValueMinError,
                buyValueMaxError,
                ordersQuantityMinError,
                ordersQuantityMaxError,
                frequencyMinError,
                frequencyMaxError,
                volumeLimitError,
                minOrderVolumeError,
                maxOrderVolumeError,
            },
        } = this.state;
        const { availableNewPairs } = this.props;

        const hasData = !error;

        const errorMessage = error ? <ErrorIndicator /> : null;
        const content = hasData ? (
            <CreateNewOrderView
                availableNewPairs={availableNewPairs}
                inputOnchange={this.inputOnchange}
                selectOnChange={this.selectOnChange}
                fakeVolumeControl={fakeVolumeControl}
                fakeVolumeControlToggle={this.fakeVolumeControlToggle}
                confirm={this.confirm}
                pairName={pairName}
                pairNameError={pairNameError}
                userId={userId}
                userIdError={userIdError}
                buySellCoef={buySellCoef}
                buySellCoefError={buySellCoefError}
                buyValueMin={buyValueMin}
                buyValueMinError={buyValueMinError}
                buyValueMax={buyValueMax}
                buyValueMaxError={buyValueMaxError}
                ordersQuantityMin={ordersQuantityMin}
                ordersQuantityMinError={ordersQuantityMinError}
                ordersQuantityMax={ordersQuantityMax}
                ordersQuantityMaxError={ordersQuantityMaxError}
                frequencyMin={frequencyMin}
                frequencyMinError={frequencyMinError}
                frequencyMax={frequencyMax}
                frequencyMaxError={frequencyMaxError}
                volumeLimit={volumeLimit}
                volumeLimitError={volumeLimitError}
                minOrderVolumeError={minOrderVolumeError}
                maxOrderVolumeError={maxOrderVolumeError}
                minOrderVolume={minOrderVolume}
                maxOrderVolume={maxOrderVolume}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {content}
            </Fragment>
        );
    }
}

CreateNewOrderContainer.defaultProps = {
    t: () => {},
    createScheduledOrder: () => {},
    getAvailableNewPairs: () => {},
    availableNewPairs: [],
    successScheduledOrder: false,
    history: {},
};

CreateNewOrderContainer.propTypes = {
    t: PropTypes.func,
    createScheduledOrder: PropTypes.func,
    getAvailableNewPairs: PropTypes.func,
    availableNewPairs: PropTypes.any,
    successScheduledOrder: PropTypes.bool,
    history: PropTypes.object,
};

const mapStateToProps = state => {
    const {
        availableNewPairs: { data: availableNewPairs },
        scheduledOrder: { success: successScheduledOrder },
    } = state;

    return {
        availableNewPairs,
        successScheduledOrder,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) =>
    bindActionCreators(
        {
            getAvailableNewPairs: getAvailableNewPairsAction(getService),
            createScheduledOrder: fetchCreateScheduledOrderAction(postService),
        },
        dispatch,
    );

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(CreateNewOrderContainer);
